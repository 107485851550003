import inputFile from './inputFile'
import { upload_image, upload_video } from '../api/upload/upload'

// 上传图片
export const uploadImage = async (regularFun) => {
  try {
    const e = await inputFile('.jpg,.jpeg,.png,.gif')
    const file = e.target.files[0]
    // 额外的规则
    if (regularFun) {
      regularFun(file)
    }
    return await upload_image(file)
  } catch (err) {
    throw err
  }
}

// 上传视频
export const uploadVideo = async (regularFun) => {
  try {
    const e = await inputFile('.mp4')
    const file = e.target.files[0]
    // 额外的规则
    if (regularFun) {
      regularFun(file)
    }
    return await upload_video(file)
  } catch (err) {
    throw err
  }
}
