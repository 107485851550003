<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数字化管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>自媒体配置</span>
            <el-button style="float: right; padding: 3px 0;margin: 0 5px;" type="text" @click="weMediaDisabled = false">编辑</el-button>
            <el-button v-show="!weMediaDisabled" style="float: right; padding: 3px 0;margin: 0 5px;" type="text" @click="addWeMediaConfig">添加</el-button>
          </div>
          <div v-if="digitizationData.we_media">
            <el-form v-for="(item, index) in digitizationData.we_media" :key="index" :disabled="weMediaDisabled" :ref="'weMediaFormRef' + index" label-width="100px">
              <div class="we-media-title">
                <h4>自媒体配置: {{ index + 1 }}</h4>
                <span v-show="!weMediaDisabled" @click="delWeMediaConfig(index)">删除</span>
              </div>
              <el-form-item label="标题(中文)" prop="">
                <el-input placeholder="请输入内容" v-model="item.layout_data_cn.title"></el-input>
              </el-form-item>
              <el-form-item label="标题(英文)" prop="">
                <el-input placeholder="请输入内容" v-model="item.layout_data_us.title"></el-input>
              </el-form-item>
              <el-form-item label="图片(中)" prop="">
                <image-upload :disabled="weMediaDisabled" v-model="item.layout_data_cn.image_path"></image-upload>
              </el-form-item>
              <el-form-item label="图片(英)" prop="">
                <image-upload :disabled="weMediaDisabled" v-model="item.layout_data_us.image_path"></image-upload>
              </el-form-item>
              <el-form-item label="跳转路径(中)" prop="">
                <el-input placeholder="请输入跳转路径" v-model="item.layout_data_cn.route"></el-input>
              </el-form-item>
              <el-form-item label="跳转路径(英)" prop="">
                <el-input placeholder="请输入跳转路径" v-model="item.layout_data_us.route"></el-input>
              </el-form-item>
            </el-form>
            <el-form>
              <el-form-item v-show="!weMediaDisabled">
                <el-button type="primary" @click="weMediaSaveClick">保存</el-button>
                <el-button @click="weMediaDisabled = true">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>其他配置</span>
            <el-button style="float: right; padding: 3px 0;margin: 0 5px;" type="text" @click="restsDisabled = false">编辑</el-button>
            <el-button v-show="!restsDisabled" style="float: right; padding: 3px 0;margin: 0 5px;" type="text" @click="addRestConfig">添加</el-button>
          </div>
          <div v-if="digitizationData.rests">
            <el-form v-for="(item, index) in digitizationData.rests" :key="index" :disabled="restsDisabled" :ref="'restsFormRef' + index" label-width="100px">
              <div class="we-media-title">
                <h4>其他配置: {{ index + 1 }}</h4>
                <span v-show="!restsDisabled" @click="delRestConfig(index)">删除</span>
              </div>
              <el-form-item label="标题(中文)" prop="">
                <el-input placeholder="请输入内容" v-model="item.layout_data_cn.title"></el-input>
              </el-form-item>
              <el-form-item label="标题(英文)" prop="">
                <el-input placeholder="请输入内容" v-model="item.layout_data_us.title"></el-input>
              </el-form-item>
              <el-form-item label="说明(中文)" prop="">
                <el-input placeholder="请输入内容" v-model="item.layout_data_cn.subhead"></el-input>
              </el-form-item>
              <el-form-item label="说明(英文)" prop="">
                <el-input placeholder="请输入内容" v-model="item.layout_data_us.subhead"></el-input>
              </el-form-item>
              <el-form-item label="图片(中)" prop="">
                <image-upload :disabled="restsDisabled" v-model="item.layout_data_cn.image_path"></image-upload>
              </el-form-item>
              <el-form-item label="图片(英)" prop="">
                <image-upload :disabled="restsDisabled" v-model="item.layout_data_us.image_path"></image-upload>
              </el-form-item>
              <el-form-item label="跳转路径(中)" prop="">
                <el-input placeholder="请输入跳转路径" v-model="item.layout_data_cn.route"></el-input>
              </el-form-item>
              <el-form-item label="跳转路径(英)" prop="">
                <el-input placeholder="请输入跳转路径" v-model="item.layout_data_us.route"></el-input>
              </el-form-item>
            </el-form>
            <el-form>
              <el-form-item v-show="!restsDisabled">
                <el-button type="primary" @click="restSaveClick">保存</el-button>
                <el-button @click="restsDisabled = true">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>    
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import imageUpload from '@/components/imageupload/image-upload.vue'
import { editRests, editWeMedia } from '@/api/digitization/digitization'
export default {
  data() {
    return {
      weMediaDisabled: true,
      restsDisabled: true,
      queryInfo: {
        type: 0
      }
    }
  },
  components: {
    imageUpload
  },
  mounted() {
    this.getDataConfig()
  },
  computed: {
    ...mapState('digitization', ['digitizationData'])
  },
  methods: {
    getDataConfig () {
      this.getData(this.queryInfo)
    },
    ...mapActions('digitization', ['getData']),
    ...mapMutations('digitization', ['addWeMediaConfig', 'delWeMediaConfig', 'delRestConfig', 'addRestConfig']),
    // 自媒体配置保存
    async weMediaSaveClick () {
      const result = await editWeMedia({
        cn: this.digitizationData.we_media.map(v => v.layout_data_cn),
        us: this.digitizationData.we_media.map(v => v.layout_data_us),
      })
      if (result.success) {
        this.$message.success('已保存')
        this.weMediaDisabled = true
      }
    },
    // 其他配置保存
    async restSaveClick () {
      const result = await editRests({
        cn: this.digitizationData.rests.map(v => v.layout_data_cn),
        us: this.digitizationData.rests.map(v => v.layout_data_us),
      })
      if (result.success) {
        this.$message.success('已保存')
        this.restsDisabled = true
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .el-row {
    margin-bottom: 20px;
    .el-card {
      margin: 0 10px;
      margin-bottom: 20px;
    }
  }
  .we-media-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: red;
      cursor: pointer;
    }
  }
</style>