import requst from '@/utils/request'

// 上传图片
export function upload_image(file) {
  const form = new FormData();
  form.append("file", file);
  return requst.request({
    url: '/general/upload/image',
    method: 'put',
    data: form
  })
}

// 上传视频
export function upload_video(file) {
  const form = new FormData();
  form.append("file", file);
  return requst.request({
    url: '/general/upload/video',
    method: 'put',
    data: form
  })
}
