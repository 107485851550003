export default function (fileType = '*', multiple = false, capture) {
  return new Promise(resolve => {
    if (!fileType) throw new Error('The file type needs to be specified')
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', fileType)
    if (multiple) {
      input.setAttribute('multiple', 'multiple')
    }
    if (capture) {
      input.setAttribute('capture', capture)
    }
    input.onchange = (e) => {
      resolve(e)
      input.remove()
    }
    input.click()
  }).catch(err => {
    throw err
  })
}