<template>
  <div class="upload-content" :class="disabled ? 'disabled' : ''" @click="uploadClick">
    <div v-if="!value" class="upload-btn">
      <i class="el-icon-plus"></i>
    </div>
    <video v-else-if="type=='video'" :src="value"></video>
    <el-image v-else slot="error" fit="fill" :src="value" >
      <i class="el-icon-picture-outline"></i>
    </el-image>
  </div>
</template>

<script>
import { uploadImage, uploadVideo } from '@/utils/uploadComp'
export default {
  props: {
    value: String,
    type: {
      type: String,
      default: 'image'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      logoPath: ''
    }
  },
  mounted () {
  
  },
  methods: {
    async uploadClick () {
      if (this.disabled) return
      if (this.type == 'video') {
        this.videoUpload()
      } else {
        this.imageUpload()
      }
    },
    async imageUpload () {
      const result = await uploadImage((file) => {
        if (file.size > 1024 * 1024 * 4) {
          const message = '大小不能大于4M'
          this.$message.error(message)
          throw new Error(message)
        }
        this.$emit('file', file)
      })
      if (result.success) {
        this.$emit('input', result.data.visit_path)
        this.$message.success('上传成功')
      }
    },
    async videoUpload () {
      const result = await uploadVideo((file) => {
        if (file.size > 1024 * 1024 * 100) {
          const message = '大小不能大于100M'
          this.$message.error(message)
          throw new Error(message)
        }
        this.$emit('file', file)
      })
      if (result.success) {
        this.$emit('input', result.data.visit_path)
        this.$message.success('上传成功')
      }
    }
  }
}
</script>


<style scoped lang="less">
  .upload-content {
    height: 120px;
    // min-width: 120px;
    .upload-btn {
      width: 100%;
      height: 100%;
      line-height: 120px;
      cursor: pointer;
      border: 1px dashed #999;
      border-radius: 10px;
      position: relative;
      i {
        width: 100%;
        font-size: 80px;
        color: #999;
        text-align: center;
        line-height: 120px;
      }
    }
    .el-image {
      height: 100%;
      cursor: pointer;
      position: relative;
    }
    video {
      height: 100%;
      cursor: pointer;
      position: relative;
    }
  }
  .disabled {
    .upload-btn,.el-image,video {
      &::after {
        content: '';
        position: absolute;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background: #a5a5a5;
        opacity: 0.5;
        top: 0;
        left: 0;
      }
    }
  }
</style>